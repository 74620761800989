:root {
  --page-size: 100vw;
}


@font-face {
  font-family: "DMSans";
  src: url("./assets/DMSans-VariableFont_opsz,wght.ttf");
}

body {
  background-color: #f5f5f5;
  /* background-color: white; */
  font-family: "DMSans", sans-serif;
  color: #28262c;
}

.dmvphoto {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  border: #28262c 3px solid;
}

.content {
  /* min-width: 20%; */
  max-width: fit-content;
  margin-inline: auto;
  padding: 10px;
  top: 50px;
  position: relative;
  width: 650px;
}

@media (max-width: 720px) {
  .content {
    /* min-width: 20%; */
    max-width: fit-content;
    margin-inline: auto;
    padding: 10px;
    top: 50px;
    position: relative;
    width: 100vw;
  }
}

h1 {
  font-size: 46px;
  text-align: center;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

p {
  font-size: 20px;
}

.top-bar {
  position: fixed;
  background-color: #73937e;
  color: #28262c;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  padding-top: 5px;
  padding-left: 25px;
  justify-content: space-between;
  align-items: center;
  transition: top 0.3s;
}

.top-bar.hidden {
  top: -70px;
}

.top-bar.visible {
  top: 0;
}

.try-links {
  max-width: fit-content;
  margin-inline: auto;
  padding: 10px;
  top: 50px;
  position: relative;
  text-align: center;
}
